import { IPartnerAppSettings } from "../interface/IPartnerAppSettings";

export const uat: IPartnerAppSettings = {
  cdnBaseUrl: "https://PJMUAT-esfacmanbaahe2c5.b01.azurefd.net/pjmux-react",
  serviceEndPoints: [
    {
      serviceEndPoint: "https://projectservices-api-uat.z01.azurefd.net/api/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint: "https://forecastservice-uat.azurewebsites.net/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint:
        "https://projectservices-api-uat.z01.azurefd.net/functions/api",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint: "https://esxp-uat.azurefd.net/grm",
      clientId: "778329d6-a980-4f3e-bf02-7bc44a384e15"
    },
    {
      serviceEndPoint: "https://esxp-uat.azurefd.net/request",
      clientId: "778329d6-a980-4f3e-bf02-7bc44a384e15"
    },
    {
      serviceEndPoint:
        "https://esxp-uat.azurefd.net/grm/rm/api",
      clientId: "778329d6-a980-4f3e-bf02-7bc44a384e15"
    },
    {
      serviceEndPoint:
        "https://professionalservicesint.microsoft.com/time-core/",
      clientId: "5eb170f9-f828-4121-936c-288eb43b050e"
    },
    {
      serviceEndPoint: "https://oneprofileuatapipack.azurefd.net",
      clientId: "99b7fa0a-a125-4f7a-abea-efb429bb8972"
    },
    {
      serviceEndPoint: "https://availabilityuat-wus.azurewebsites.net/api",
      clientId: "4abd490b-ed66-48bf-a402-61c8c529b6ec"
    },
    {
      serviceEndPoint: "https://forecastfunctions-uat.azurewebsites.net/api/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint: "https://projectservices-api-uat.z01.azurefd.net/functions/api",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    },
    {
      serviceEndPoint: "https://planservice-uat.azurewebsites.net/api/v1/",
      clientId: "f9f20a9f-d0d3-4633-ba06-c211afb45bb4"
    }
  ],
  PjmAppConfiguration: {
    projectServiceBaseUri:
      "https://projectservices-api-uat.z01.azurefd.net/api/",
    projectServiceForecastBaseUri:
      "https://forecastservice-uat.azurewebsites.net",
    projectServicePlanBaseUri:
      "https://planservice-uat.azurewebsites.net/api/v1/",
    forecastFunctionsBaseUri:
      "https://forecastfunctions-uat.azurewebsites.net/api/",
    projectServiceFunctionV2BaseUrl:
      "https://projectservices-api-uat.z01.azurefd.net/functions/api",
    projectServiceSubscriptionKey: "dc42c2d47e5d4162aa39d4114015717d",
    utilityApiPath: "dmutility/api",
    fluentUiCdn:
      "https://res.cdn.office.net/files/fabric-cdn-prod_20241209.001/assets/icons/",
    fxpFlightingUrl:
      "https://flighting-api-preprod.azurefd.net/api/v1/evaluate?featureNames=QuotesAccess,OBOReadAccess,OBOReadWriteAccess,RenewalAccess",
    grmFedSearchLink: "https://esxp-uat.azurefd.net//grm/read/api/Search",
    grmBulkMaintenanceBaseUrl: "https://esxp-uat.azurefd.net/grm/",
    grmForecastBaseUrl: "https://availabilityuat-wus.azurewebsites.net/api",
    grmBaseUrl:
      "https://esxp-uat.azurefd.net/grm/rm/api",
    grmSubscriptionKey: "4f1ac558346d474facea88125516f6eb",
    grmForecastSubscriptionKey: "",
    laborManagementServiceBaseUri:
      "https://professionalservicesint.microsoft.com/time-core/",
    laborManagementServiceSubscriptionKey: "fd723c776fbd432fb12471dcf25d5c10",
    salesOrderServiceBaseUri:
      "https://professionalservicesint.microsoft.com/o2c/som/uat/api",
    salesOrderServiceSubscriptionKey: "55d23b2e2b20437c984d220e15f56722",
    oneProfileBaseUri: "https://oneprofileuatapipack.azurefd.net",
    fileSupportedFormatEarlyTerminationLetter: ".pdf , .docx, .msg",
    POCCValue: 10.00,
    ForecastClarityId: "k97j42f93y"
  }
};
